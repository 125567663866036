import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep, setEditEnabled, setCurrentGuest, setUsers, setTickets } from "../../app/store";
import ActionButton from "../interface/button";
import BreadCrumbs from "../interface/breadcrumbs";
import { Fetch } from "../../util/api";
import { Steps, getStepName } from "../../util/helpers";
import { formatWaitTime, formatSummaryWaitTime } from "../../util/time-formatting";
import { logPageView } from "../../app/analytics";

function Summary({ isMobile, barbersData }) {
  const dispatch = useDispatch();
  const checkInData = useSelector((state) => state.webCheckIn);
  const [isNextActive, setIsNextActive] = useState(true);
  // const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [reminderChecked, setReminderChecked] = useState(true);
  const [barberWaitTimes, setBarberWaitTimes] = useState(null);
  const isFetchingBarberWaitimes = useRef(false);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [updatedUsers, setUpdatedUsers] = useState(null);
  const [barbers, setBarbers] = useState(barbersData);

  const retryFetch = async (url, method, data, retryCount = 3) => {
    try {
      const response = await Fetch(url, method, data);

      return response;
    } catch (error) {
      if (retryCount <= 1) throw error;

      return retryFetch(url, method, data, retryCount - 1);
    }
  };

  const createWalkinForShop = async (user) => {
    const bookingData = {
      User: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        shortcutsId: user.shortcutsId,
      },
      services: user.selectedServices.map((service) => {
        const serviceItem = {
          EmployeeId: parseInt(user.selectedBarber[0].referenceId),
          ServiceId: parseInt(service.referenceId),
        };
        return serviceItem;
      }),
    };
    return await retryFetch(`/webcheckin/CreateWalkinForShop/${checkInData.shopInfo.hours.id}`, "POST", JSON.stringify(bookingData));
  };

  const getBarberWaitTimes = async () => {
    return await retryFetch(`/webcheckin/barberWaitTimes/${checkInData?.shopInfo?.hours?.id}`);
  };

  const confirmWalkinMobile = async (data, user, barberWaitTimes) => {
    const selectedBarberWaitTime = barberWaitTimes.employee_wait_time.find((barber) => barber.employee_id === parseInt(user.selectedBarber[0].referenceId));

    let notificationMinutes = 0;

    switch (true) {
      case selectedBarberWaitTime >= 50:
        notificationMinutes = 35;
        break;
      case selectedBarberWaitTime >= 40:
        notificationMinutes = 25;
        break;
      case selectedBarberWaitTime >= 25:
        notificationMinutes = 15;
        break;
      case selectedBarberWaitTime >= 15:
        notificationMinutes = 5;
        break;
      default:
        notificationMinutes = 0;
        break;
    }

    const confirmationData = {
      delay: 0,
      notificationMinutes: notificationMinutes,
      smsNumber: checkInData.user.formattedNumber,
      visitId: data.href.match(/\/(\d+)$/)[1],
      customerId: user.shortcutsId,
      // Add any other required data from barberWaitTimes
    };
    return await retryFetch(`/webcheckin/ConfirmWalkinMobile/${checkInData.shopInfo.hours.id}`, "POST", JSON.stringify(confirmationData));
  };

  const cleanTicketsForPhoneNumber = async () => {
    const ticketsData = {
      phoneNumber: checkInData.user.formattedNumber,
    };
    return await retryFetch(`/webcheckin/CleanTicketsForPhoneNumber`, "POST", JSON.stringify(ticketsData));
  };

  const createWalkin = async () => {
    setIsNextActive(false);
    setShowError(false);
    setErrorMessage("");

    for (let user of checkInData.users) {
      if (!checkInData.tickets?.find((ticket) => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo?.hours.id))) {
        const timeout = new Promise((resolve, reject) => setTimeout(() => reject("timeout"), 15000));
        try {
          const walkinData = await createWalkinForShop(user);
          const barberWaitTimes = await getBarberWaitTimes();
          setBarberWaitTimes(barberWaitTimes); // Calling getBarberWaitTimes
          try {
            const confirmation = await Promise.race([timeout, confirmWalkinMobile(walkinData, user, barberWaitTimes)]); // Passing barberWaitTimes to confirmWalkinMobile

            logPageView("confirmed_check_in", {
              shop_name: checkInData?.shopInfo?.hours?.shopName,
              shop_id: checkInData?.shopInfo?.hours?.id,
              shop_country: checkInData?.shopInfo?.country,
              page: getStepName(checkInData.currentStep).toString(),
              wait_time: confirmation.waitTime,
              shop_status: checkInData?.shopInfo?.shopStatus,
              phoneNumber: checkInData?.user?.phoneNumber,
              user: confirmation.shortcutsId,
              services: [
                user.selectedServices.map((service) => {
                  return {
                    service_id: service.id,
                    reference_id: service.referenceId,
                    service_name: service.name,
                    service_price: service.price,
                    service_description: service.description,
                    service_type: service.type,
                    service_duration: service.duration,
                  };
                }),
              ],
              barber: user.selectedBarber.map((barber) => {
                return {
                  barber_id: barber.id,
                  barber_name: barber.name,
                  barber_wait_time: barber.waitTime,
                };
              }),
            });
          } catch (error) {
            throw error;
          }
        } catch (error) {
          logPageView("confirmation_error", {
            shop_name: checkInData?.shopInfo?.hours?.shopName,
            shop_country: checkInData?.shopInfo?.country,
            page: getStepName(checkInData.currentStep).toString(),
            wait_time: checkInData?.shopInfo?.waitTime,
            shop_status: checkInData?.shopInfo?.shopStatus,
            error_message: "Unable to create walk-in",
          });
          setErrorMessage(`Unable to create check-in for ${user.firstName}, please try again`);
          setShowError(true);
          setIsNextActive(true);
          return;
        }

        setTimeout(() => {}, 3000);
      }
    }

    try {
      const ticketsData = await cleanTicketsForPhoneNumber();
      dispatch(setTickets(ticketsData));
      setIsNextActive(true);
      dispatch(setCurrentStep(Steps.FINAL_CONFIRMATION));
    } catch {
      logPageView("confirmation_error", {
        shop_name: checkInData?.shopInfo?.hours?.shopName,
        shop_country: checkInData?.shopInfo?.country,
        page: getStepName(checkInData.currentStep).toString(),
        wait_time: checkInData?.shopInfo?.waitTime,
        shop_status: checkInData?.shopInfo?.shopStatus,
        error_message: "Unable to retrieve tickets",
      });
      setErrorMessage("Unable to retrieve account tickets");
      setShowError(true);
      setIsNextActive(true);
    }
  };

  const handleBack = () => {
    if (isNextActive) {
      dispatch(setEditEnabled(false));
      const nextGuest = checkInData.users.length - 1;
      dispatch(setCurrentGuest(nextGuest));
      dispatch(setCurrentStep(Steps.SELECT_BARBER));
    }
  };

  const handleNext = () => {
    createWalkin();
  };

  const handleServiceEditClick = (user) => {
    if (isNextActive) {
      dispatch(setEditEnabled(true));
      dispatch(setCurrentGuest(user));
      dispatch(setCurrentStep(Steps.SELECT_SERVICES));
    }
  };

  const handleBarberEditClick = (user) => {
    if (isNextActive) {
      dispatch(setEditEnabled(true));
      dispatch(setCurrentGuest(user));
      dispatch(setCurrentStep(Steps.SELECT_BARBER));
    }
  };

  const handleReminderClick = () => {
    setReminderChecked(!reminderChecked);
  };

  const handleRemoveGuest = (guest) => {
    if (isNextActive) {
      const newGuestList = checkInData.users.filter((user) => user.id !== guest.id);
      const newUpdatedGuestList = updatedUsers.filter((user) => user.id !== guest.id);
      dispatch(setUsers(newGuestList));
      setUpdatedUsers(newUpdatedGuestList);
      dispatch(setCurrentGuest(newGuestList.length - 1));
      if (newGuestList.length === 0) {
        dispatch(setCurrentGuest(0));
        dispatch(setCurrentStep(Steps.GUEST_SELECT));
      }
    }
  };

  const fetchBarberWaitTimes = async () => {
    if (isFetchingBarberWaitimes.current === true) return;

    isFetchingBarberWaitimes.current = true;
    try {
      await Fetch(`/webcheckin/barberWaitTimes/${checkInData?.shopInfo?.hours?.id}`).then((data) => {
        if (data) {
          setBarberWaitTimes(data.employee_wait_time); // Set barber data
          setLoading(false);
        }
      });
    } catch (error) {
      isFetchingBarberWaitimes.current = false;
    } finally {
      isFetchingBarberWaitimes.current = false;
    }
  };

  function findLowestWaitTime(users, barberWaitTimes) {
    let minWaitTime = Infinity;
    const guests = users;
    const waitTimes = barberWaitTimes?.employee_wait_time || null;

    if (guests && waitTimes) {
      guests.forEach((user) => {
        const selectedBarberRefId = user.selectedBarber[0].referenceId;
        const barberWaitTimeObj = waitTimes?.find((barber) => barber.employee_id === selectedBarberRefId);

        if (barberWaitTimeObj && barberWaitTimeObj.wait_time < minWaitTime) {
          minWaitTime = barberWaitTimeObj.wait_time;
        }
      });

      return minWaitTime === Infinity ? null : minWaitTime;
    }
  }

  useEffect(() => {
    const calculateWaitTime = (users, shopInfo, barberWaitTimes, barbers, tickets) => {
      //console.log()
      const availableBarberIds = barbers?.employees?.map((barber) => parseInt(barber.referenceId)) || [];

      let sortedBarbers = JSON.parse(JSON.stringify(barberWaitTimes))
        .filter((barber) => availableBarberIds.includes(barber.employee_id))
        .filter((barber) => barber.employee_id !== 1)
        .sort((a, b) => a.wait_time - b.wait_time);

      const updatedUsers = [];

      for (const user of users) {
        // Check if user.selectedBarber and sortedBarbers are defined and not empty
        if (!user.selectedBarber || !user.selectedBarber.length || !sortedBarbers || !sortedBarbers.length) {
          return -1;
        }

        let selectedBarber;
        if (parseInt(user.selectedBarber[0].referenceId) === 1) {
          // Next Available
          selectedBarber = sortedBarbers[0];
        } else {
          selectedBarber = sortedBarbers.find((barber) => barber.employee_id === parseInt(user.selectedBarber[0].referenceId));
        }

        // Check if user.selectedServices is defined and an array
        if (!Array.isArray(user.selectedServices)) {
          return -1;
        }

        const totalDuration = user.selectedServices.reduce((acc, service) => acc + service.duration, 0);

        // Record the wait time for the user before we update the barber's time
        updatedUsers.push({
          ...user,
          calculatedWaitTime: tickets?.find((ticket) => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo?.hours.id))?.waitTime || selectedBarber.wait_time,
        });

        // Update the wait time for the selected barber
        selectedBarber.wait_time += totalDuration;
      }

      return updatedUsers;
    };

    if (checkInData.users && checkInData.shopInfo && Array.isArray(barberWaitTimes)) {
      setUpdatedUsers(calculateWaitTime(checkInData.users, checkInData.shopInfo, barberWaitTimes, barbers, checkInData.tickets));
    }
  }, [barbers, barberWaitTimes, checkInData.shopInfo, checkInData.tickets, checkInData.users]);

  useEffect(() => {
    fetchBarberWaitTimes();

    // Set up the interval to update the barber wait times every 15 seconds
    const intervalId = setInterval(() => {
      fetchBarberWaitTimes();
    }, 15000);

    // Clean up the interval when the component is unmounted or when the dependencies change
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row w-100 p-0 py-1 py-lg-5 m-0 flex-column flex-lg-row h-100">
        <div className="col-lg-6 order-3 order-lg-2 h-100 d-flex tab-content">
          <div id="nav-gft_embed_summary" className="col-12 h-100 w-100 flex-column px-0 px-md-5 m-0 gft-content flex-nowrap">
            <div className="gft-header user-select-none">
              <h2 className="gft-view-heading mb-3">Summary</h2>
              <p className="gft-view-desc mb-3 mb-lg-5">Your selection summary is below, you may edit any of the details. Once you select CONFIRM CHECK-IN your services will be confirmed.</p>
            </div>
            {checkInData?.user?.phoneInputCountry !== "ca" ? (
              <div className="mb-5">
                <input type="checkbox" className="summary-check" id="summary-check" checked={reminderChecked} onClick={handleReminderClick} readOnly disabled={!isNextActive} />
                <label className="d-flex flex-row" htmlFor="summary-check">
                  <div></div>
                  <span className="remember-me">Send {checkInData.users.length > 1 ? "reminder(s)" : "reminder"} over SMS</span>
                </label>
              </div>
            ) : (
              <></>
            )}
            {loading ? (
              <div className="align-items-start mx-auto loading-text">
                <ActionButton btnPrimary={true} text="Loading..." isActive={false} onClick={() => {}} noBg={true} />
              </div>
            ) : (
              <form id="gftForm-gft_embed_summary" className="gft-form gft-summary">
                {updatedUsers?.map((user, userIndex) => {
                  //const barberIndex = barberWaitTimes?.findIndex((employee) => employee.employee_id === parseInt(user.selectedBarber[0].referenceId));
                  //const barberWaitTime = checkInData.tickets?.find(ticket => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo?.hours.id))?.waitTime || barberIndex === -1 ? checkInData.shopInfo.waitTime : barberWaitTimes[barberIndex === 1 ? 0 : barberIndex].wait_time;

                  return (
                    <div className="mb-2" key={userIndex}>
                      <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                        <div className="d-flex flex-row w-100 align-items-end justify-content-between">
                          <h5 className="user-select-none serviceHeading">{user.firstName + " " + user.lastName.charAt(0)}</h5>
                          <h6 className="user-select-none serviceHeadingWaitTime">
                            {!isNextActive
                              ? checkInData.tickets?.find((ticket) => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo?.hours.id))
                                ? formatSummaryWaitTime(checkInData.tickets?.find((ticket) => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo?.hours.id))?.waitTime)
                                : "...updating"
                              : formatSummaryWaitTime(
                                  checkInData.tickets?.find((ticket) => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo?.hours.id))?.waitTime || user.calculatedWaitTime
                                )}
                          </h6>
                        </div>
                        {isNextActive ? (
                          <div
                            className={`w-100 user-select-none serviceHeadingRemove d-flex flex-column align-items-center summaryRemove ${!isNextActive ? "text-secondary" : null}`}
                            role="button"
                            onClick={() => {
                              handleRemoveGuest(user);
                            }}
                            disabled={!isNextActive}
                          >
                            {/* <span>Remove</span> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-x" viewBox="0 0 16 16">
                              <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708Z" />
                            </svg>
                          </div>
                        ) : null}
                      </div>
                      <ul className="mb-4">
                        <li className="d-flex flex-row column-gap-0 w-100 h-100 justify-content-between align-items-center p-0">
                          <div className="d-flex h-100 p-3 pe-0 text-start">
                            <span className="pt-1 w-5 summaryItem" style={{ minWidth: 82 }}>
                              BARBER:
                            </span>
                          </div>
                          <div className="d-flex w-100 ps-0 pt-3 pb-3 pe-0">
                            <span className="summaryItem">
                              <p className="ps-0 pt-1 text-uppercase">{user?.selectedBarber[0]?.name}</p>
                            </span>
                          </div>
                          <div className="d-flex p-3">
                            <span className="d-flex flex-row column-gap-0 w-5 text-end summaryItem">
                              {!checkInData.tickets.some((ticket) => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo.hours.id)) ? (
                                isNextActive ? (
                                  <p
                                    className={`pt-1 w-100 pe-auto summaryEdit ${!isNextActive ? "text-secondary" : null}`}
                                    role="button"
                                    onClick={() => {
                                      handleBarberEditClick(userIndex);
                                    }}
                                    disabled={!isNextActive}
                                  >
                                    Edit
                                  </p>
                                ) : null
                              ) : null}
                            </span>
                          </div>
                        </li>
                        <li className={`d-flex flex-row column-gap-0 w-100 h-100 justify-content-between align-items-start p-0`}>
                          <div className="d-flex h-100 p-3 pe-0 text-start">
                            <span className="pt-1 w-5 summaryItem" style={{ minWidth: 82 }}>
                              SERVICES:
                            </span>
                          </div>
                          <div className="d-flex w-100 ps-0 pt-3 pe-0 pb-3">
                            <span className="summaryServices summaryItem">
                              <ol className="">
                                {user.selectedServices.map((service, serviceIndex) => {
                                  return (
                                    <li key={serviceIndex} className="rounded-0 border-0 fw-normal d-flex p-1 lh-1 text-uppercase align-items-middle justify-content-start">
                                      {service.name}
                                    </li>
                                  );
                                })}
                              </ol>
                            </span>
                          </div>
                          <div className="d-flex p-3">
                            <span className="d-flex flex-row column-gap-0 w-5 text-end summaryItem">
                              {!checkInData.tickets.some((ticket, index) => ticket.visitorId === user.id && ticket.shopId === parseInt(checkInData.shopInfo.hours.id)) ? (
                                isNextActive ? (
                                  <p
                                    className={`pt-1 w-100 pe-auto summaryEdit ${!isNextActive ? "text-secondary" : null}`}
                                    role="button"
                                    onClick={() => {
                                      handleServiceEditClick(userIndex);
                                    }}
                                    disabled={!isNextActive}
                                  >
                                    Edit
                                  </p>
                                ) : null
                              ) : null}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  );
                })}
                <div id="gft_error_checkin" className={`gft_error ${showError ? "" : "visually-hidden"}`}>
                  {errorMessage}
                </div>
              </form>
            )}
            <div className="gft-footer">
              <div className="gft-buttons">
                {!isMobile ? <ActionButton text="Back" onClick={handleBack} isActive={true} arrowLeft={true} /> : null}
                <ActionButton
                  btnPrimary={true}
                  arrowRight={true}
                  text={`${!isNextActive ? "Confirming" : "Confirm Check-In"}`}
                  onClick={handleNext}
                  isActive={isNextActive}
                  isDisabled={!isNextActive}
                  arrow={true}
                />
              </div>
            </div>
          </div>
        </div>
        <BreadCrumbs crumbs={true} activeCrumb="summary" backButton={true} backFunction={handleBack} isMobile={isMobile} waitTimeOverride={findLowestWaitTime(checkInData.users, barberWaitTimes)} />
      </div>
    </>
  );
}

export default Summary;
