import React from "react";
import PropTypes from "prop-types";

function ActionButton({
  text = "",
  onClick = () => {},
  isActive = true,
  arrowRight = false,
  arrowLeft = false,
  isDisabled = false,
  isOTP = false,
  reversed = false,
  noBg = false,
  btnPrimary = false,
  isMobile = false,
}) {
  const handleClick = (e) => {
    onClick(e);
  };

  return (
    <button
      type="button"
      className={`${btnPrimary ? (text !== "" ? "btn btn-primary ps-3" : "btn btn-primary pe-1 w-auto") : text !== "" ? "btn btn-secondary pe-3" : "btn btn-secondary pe-1 w-auto"} ${
        text.includes("Loading") ? "pe-3 bg-transparent pe-none" : ""
      } p-0 m-0 ${reversed ? null : "gft-spinner"} ${isActive ? "" : "show-spinner"} ${isOTP ? "h-50 mt-2 mt-xl-0 ms-0" : ""} ${noBg ? "noBg" : ""} ${!arrowLeft && !arrowRight ? "pe-3" : ""} ${
        isMobile ? (text !== "" ? "w-100" : "") : ""
      }`}
      onClick={handleClick}
      disabled={text.includes("Loading") ? false : isDisabled ? true : !isActive}
    >
      {arrowLeft && isActive ? (
        <img
          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMjcuMyAxMjcuMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTI3LjMgMTI3LjM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnIGlkPSJ1cF9hcnJvdyI+DQoJPHBvbHlnb24gcG9pbnRzPSI2My43LDU0LjkgODIuOCw3NC4xIDEwNCw3NC4xIDYzLjcsMzMuNyAyMy4zLDc0LjEgNDQuNSw3NC4xIAkiLz4NCjwvZz4NCjwvc3ZnPg0K"
          className={`arrowButtonLeft p-0 m-0 ${reversed ? "reversed" : null}`}
          alt="Chevron"
        />
      ) : null}
      {text}
      {/* Left+Right Chevron on Buttons */}
      {arrowRight && isActive ? (
        <img
          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMjcuMyAxMjcuMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTI3LjMgMTI3LjM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnIGlkPSJ1cF9hcnJvdyI+DQoJPHBvbHlnb24gcG9pbnRzPSI2My43LDU0LjkgODIuOCw3NC4xIDEwNCw3NC4xIDYzLjcsMzMuNyAyMy4zLDc0LjEgNDQuNSw3NC4xIAkiLz4NCjwvZz4NCjwvc3ZnPg0K"
          className={`arrowButtonRight p-0 m-0 ${reversed ? "reversed" : null}`}
          alt="Chevron"
        />
      ) : !isActive ? (
        <div className={`d-inline-block pt-0 pb-0 ps-3`} style={{ minHeight: 29 }} />
      ) : null}
    </button>
  );
}

ActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  arrowRight: PropTypes.bool,
  arrowLeft: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOTP: PropTypes.bool,
  reversed: PropTypes.bool,
  noBg: PropTypes.bool,
  btnPrimary: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default ActionButton;
