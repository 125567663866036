import { configureStore, createSlice } from "@reduxjs/toolkit";
import { Fetch } from "../util/api";
import { Steps, Platform } from "../util/helpers";

const defaultState = {
  resetState: false,
  currentStep: process.env.REACT_APP_DEV_ENABLED === "true" ? 0 : Platform === "web" ? Steps.LOGIN : Steps.SHOP_LOCATOR,
  currentGuest: 0,
  EditEnabled: false,
  initialTicketsLoaded: false,
  user: {
    phoneInputNumber: null,
    phoneInputCountry: null,
    phoneDialCode: null,
    formattedNumber: null,
    verified: false,
    OPTSent: false,
    verificationCode: null,
    consent: false,
    rememberMe: false,
    trustedDevices: [],
  },
  users: [],
  availableUsers: [],
  groupedUsers: [],
  shopInfo: {
    waitTime: null,
    shopStatus: null,
    country: null,
    hours: {
      shopName: null,
      isShopOpen: null,
      id: null,
      utcOffset: null,
      openHours: {
        sunday: { open_time: null, close_time: null, is_closed: null },
        monday: { open_time: null, close_time: null, is_closed: null },
        tuesday: { open_time: null, close_time: null, is_closed: null },
        wednesday: { open_time: null, close_time: null, is_closed: null },
        thursday: { open_time: null, close_time: null, is_closed: null },
        friday: { open_time: null, close_time: null, is_closed: null },
        saturday: { open_time: null, close_time: null, is_closed: null },
      },
    },
  },
  tickets: [],
};

const webCheckInSlice = createSlice({
  name: "webCheckIn",
  initialState: defaultState,
  reducers: {
    setInitialState(state, action) {
      return { ...state, ...action.payload };
    },
    resetState(state, action) {
      state.currentStep = defaultState.currentStep;
      state.currentGuest = defaultState.currentGuest;
      state.EditEnabled = defaultState.EditEnabled;
      state.initialTicketsLoaded = defaultState.initialTicketsLoaded;
      state.user = {
        ...state.user,
        phoneInputNumber: defaultState.user.phoneInputNumber,
        phoneInputCountry: defaultState.user.phoneInputCountry,
        formattedNumber: defaultState.user.formattedNumber,
        verified: defaultState.user.verified,
        OPTSent: defaultState.user.OPTSent,
        verificationCode: defaultState.user.verificationCode,
        consent: defaultState.user.consent,
        trustedDevices: defaultState.user.trustedDevices,
      };
      state.users = defaultState.users;
      state.availableUsers = defaultState.availableUsers;
      state.groupedUsers = defaultState.groupedUsers;
      state.tickets = defaultState.tickets;
    },
    setInitialTicketsLoaded(state, action) {
      state.initialTicketsLoaded = action.payload;
    },
    setResetState(state, action) {
      state.resetState = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setEditEnabled(state, action) {
      state.EditEnabled = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setUserphoneInputCountry(state, action) {
      state.user.phoneInputCountry = action.payload;
    },
    setPhoneDialCode(state, action) {
      state.user.phoneDialCode = action.payload;
    },
    setUserVerified(state, action) {
      state.user.verified = action.payload;
    },
    setUserVerificationCode(state, action) {
      state.user.verificationCode = action.payload;
    },
    setUserConsent(state, action) {
      state.user.consent = action.payload;
    },
    setUserRememberMe(state, action) {
      state.user.rememberMe = action.payload;
    },
    addUserTrustedDevice(state, action) {
      const newTrustedDevice = action.payload;
      state.user.trustedDevices = [...state.user.trustedDevices, newTrustedDevice];
    },
    removeUserTrustedDevice(state, action) {
      const udfToRemove = action.payload.udf;
      state.user.trustedDevices = state.user.trustedDevices.filter((device) => device.udf !== udfToRemove);
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setTickets(state, action) {
      state.tickets = action.payload;
    },
    removeUserFromUsers(state, action) {
      const userId = action.payload;
      state.users = state.users.filter((user) => user.id !== userId);
    },
    setAvailableUsers(state, action) {
      state.availableUsers = action.payload;
    },
    removeUserFromAvailableUsers(state, action) {
      const userId = action.payload;
      state.availableUsers = state.availableUsers.filter((user) => user.id !== userId);
    },
    setGroupedUsers(state, action) {
      state.groupedUsers = action.payload;
    },
    removeUserFromGroup(state, action) {
      const userId = action.payload;
      state.users = state.groupedUsers.filter((user) => user.id !== userId);
    },
    setCurrentGuest(state, action) {
      state.currentGuest = action.payload;
    },
    setShopInfo(state, action) {
      state.shopInfo = {
        ...action.payload,
        country: state.shopInfo.country,
      };
    },
    setShopCountry(state, action) {
      state.shopInfo.country = action.payload;
    },
    addUserService(state, action) {
      const { userId, service } = action.payload;
      const userIndex = state.users.findIndex((user) => user.id === userId);

      if (userIndex !== -1) {
        if (!state.users[userIndex].selectedServices) {
          state.users[userIndex].selectedServices = [service]; // if selectedServices does not exist, initialize with the service
        } else {
          state.users[userIndex].selectedServices.push(service); // if it already exists, add the service to it
        }
      }
    },
    removeUserService(state, action) {
      const { userId, serviceId } = action.payload;
      const userIndex = state.users.findIndex((user) => user.id === userId);

      if (userIndex !== -1) {
        state.users[userIndex].selectedServices = state.users[userIndex].selectedServices.filter((service) => service.id !== serviceId);
      }
    },
    setUserBarber(state, action) {
      const { userId, barber } = action.payload;
      const userIndex = state.users.findIndex((user) => user.id === userId);
      if (userIndex !== -1) {
        state.users[userIndex].selectedBarber = [barber];
      }
    },
    setCheckInData(state, action) {
      const { currentStep, currentGuest, EditEnabled, shopInfo } = state;
      state = { ...state, ...action.payload };
      state.currentStep = currentStep;
      state.currentGuest = currentGuest;
      state.EditEnabled = EditEnabled;
      state.shopInfo = shopInfo;
    },
  },
});

export const {
  setInitialState,
  setCheckInData,
  setInitialTicketsLoaded,
  resetState,
  setResetState,
  setCurrentStep,
  setCurrentGuest,
  setEditEnabled,
  setUser,
  setPhoneDialCode,
  setTickets,
  setUserphoneInputCountry,
  setUserVerified,
  setUserVerificationCode,
  setUserConsent,
  setUserRememberMe,
  addUserTrustedDevice,
  removeUserTrustedDevice,
  setUsers,
  removeUserFromUsers,
  setAvailableUsers,
  removeUserFromAvailableUsers,
  setGroupedUsers,
  removeUserFromGroup,
  setShopInfo,
  setShopCountry,
  addUserService,
  removeUserService,
  setUserBarber,
} = webCheckInSlice.actions;

const store = configureStore({
  reducer: {
    webCheckIn: webCheckInSlice.reducer,
  },
  preloadedState: { webCheckIn: { ...defaultState } },
});

let previousState = store.getState().webCheckIn;
// Subscribe to store changes and save the state to local storage
store.subscribe(() => {
  const state = store.getState().webCheckIn;

  // // If state was being reset, set ResetState to false after a delay to ensure that callStack is cleared
  // if (state.resetState) {
  //   setTimeout(() => {
  //     store.dispatch(webCheckInSlice.actions.setResetState(false));
  //   }, 0);
  // }

  // Check if anything other than shopInfo has changed
  if (!state.resetState) {
    if (!areStatesEqual(state, previousState, ["shopInfo", "tickets"])) {
      if (state.user.formattedNumber && state.shopInfo.hours.id) {
        const partitionKey = state.user.formattedNumber;
        const rowKey = state.shopInfo.hours.id;

        Fetch("/saveState", "POST", { partitionKey, rowKey, state }).catch((error) => console.error("Error saving state to Azure", error));
      } else {
        //console.log("skipped save no phoneNumber or shopInfo")
      }
    } else {
      //console.log("skipped save no state changes")
    }
  } else {
    //console.log("skipped save",state)
  }

  // Update the previousState with the current state
  previousState = state;
});

// Utility function to check if specific properties of two states are equal
function areStatesEqual(state1, state2, ignoredProperties) {
  for (const prop in state1) {
    if (!ignoredProperties.includes(prop) && state1[prop] !== state2[prop]) {
      return false;
    }
  }
  return true;
}

export default store;
