import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/app-routes";

function App() {
  console.log("rendering app");
  return (
    <div className="gft-embed">
      {/* <div className="gft-dots"></div> */}
      <div className="container-xl relative h-100 py-2 px-md-3 px-lg-5 d-flex">
        <Router>
          <AppRoutes />
        </Router>
      </div>
      <div className="d-flex flex-row gap-3 justify-content-center fixed-bottom ps-3 pb-3 fs-6">
        <a style={{ color: "white" }} href="https://tommyguns.com/policies/privacy-policy" rel="noreferrer" target="_blank">
          Privacy Policy
        </a>
        <a style={{ color: "white" }} href="https://tommyguns.com/policies/terms-of-service" rel="noreferrer" target="_blank">
          Terms of Service
        </a>
      </div>
    </div>
  );
}

export default App;
