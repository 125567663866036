import { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setShopInfo, setShopCountry, setUserphoneInputCountry, setCurrentGuest, setCurrentStep, setTickets, resetState, setResetState, removeUserFromUsers } from "../app/store";
import AppModal from "./app-modal";
import { Fetch } from "../util/api";
import {
  Login,
  Verification,
  Register,
  GuestSelect,
  ManageAccount,
  ManageGuests,
  AddGuest,
  TrustedDeviceManager,
  DevGateway,
  GroupServices,
  SelectServices,
  SelectBarber,
  Summary,
  FinalConfirmation,
} from "./checkin";
import CryptoJS from "crypto-js";
import getBrowserFingerprint from "get-browser-fingerprint";
import { Steps, getStepName } from "../util/helpers";
import { useDeviceData } from "react-device-detect";
import ErrorBoundary from "./error-boundary";
import { logPageView } from "../app/analytics";

const countryCodes = {
  Canada: "ca",
  "United States": "us",
  Australia: "au",
  "New Zealand": "nz",
};

function WebCheckIn() {
  const dispatch = useDispatch();
  const checkInData = useSelector((state) => state.webCheckIn);
  const { id } = useParams();
  const parsedId = parseInt(id);
  const sanitizedId = parsedId.toString().replace(/[^0-9]/g, "");
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 991px)").matches);
  const userDeviceFingerprint = getBrowserFingerprint();
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const inactivityTimer = useRef(null);
  const deviceInfo = useDeviceData();
  const [barbers, setBarbers] = useState(null);
  const [uiState, setUiState] = useState({
    loading: true,
    showErrorModal: false,
    showNoBarberErrorModal: false,
  });
  const isFetchingData = useRef(false);
  const isFetchingTickets = useRef(false);

  const userDeviceInfo = {
    os: deviceInfo.os.name,
    osVersion: deviceInfo.os.osVersion,
    browser: deviceInfo.browser.name,
    browserVersion: deviceInfo.browser.version,
    device: `${deviceInfo.device.type ? deviceInfo.device.type + "|" + deviceInfo.device.vendor + "-" + deviceInfo.device.model : "PC"}`,
  };

  const handleCancelCheckIn = (ticketId, userId) => {
    return async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const data = { visitId: ticketId };
          const response = await Fetch(`/webcheckin/CancelWalkin/${checkInData?.shopInfo?.hours.id}`, "POST", data);

          dispatch(removeUserFromUsers(userId));

          await fetchTickets();
          await fetchData(false);

          // Send to GA TGM
          logPageView("service_cancellation", {
            shop_name: checkInData?.shopInfo?.hours?.shopName,
            shop_country: checkInData?.shopInfo?.country,
            page: getStepName(checkInData.currentStep).toString(),
            wait_time: checkInData?.shopInfo?.waitTime,
            shop_status: checkInData?.shopInfo?.shopStatus,
          });

          resolve(response);
        } catch (error) {
          // Send to GA TGM
          logPageView("service_cancellation_error", {
            shop_name: checkInData?.shopInfo?.hours?.shopName,
            shop_country: checkInData?.shopInfo?.country,
            page: getStepName(checkInData.currentStep).toString(),
            wait_time: checkInData?.shopInfo?.waitTime,
            shop_status: checkInData?.shopInfo?.shopStatus,
            error_message: "Failed to cancel user requested confirmed check-in ",
          });

          reject(error);
        }
      });
    };
  };

  const resetTimer = useCallback(() => {
    if (checkInData.currentStep !== Steps.DEV_GATEWAY && checkInData.currentStep !== Steps.LOGIN && checkInData.currentStep !== Steps.FINAL_CONFIRMATION) {
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
      inactivityTimer.current = setTimeout(() => {
        setShowInactivityModal(true);
      }, 120000); //2 minutes
    }
  }, [checkInData.currentStep]);

  const fetchData = useCallback(async (isInitial = false) => {
    if (isFetchingData.current === true) {
      return;
    }

    isFetchingData.current = true;

    try {
      // Start loading
      if (isInitial) {
        setUiState((prev) => ({ ...prev, loading: true }));
      }

      // Fetch shop status
      const shopData = await Fetch(`/webcheckin/GetShopStatus/${sanitizedId}`, "GET", null);
      dispatch(setShopInfo(shopData));

      // Fetch country
      const countryData = await Fetch(`/webcheckin/GetShopCountry/${sanitizedId}`, "GET", null);
      dispatch(setShopCountry(countryCodes[countryData.address.country]));
      if (isInitial === true) {
        dispatch(setUserphoneInputCountry(checkInData.user.phoneInputCountry || countryCodes[countryData.address.country]));
      }

      // Fetch barbers
      const barbersData = await Fetch(`/webcheckin/GetBarbersForShop/${sanitizedId}`, "GET", null);
      if (barbersData?.employees) {
        if (barbersData?.employees?.length === 1 && shopData.shopStatus === "Open") {
          setUiState((prev) => ({ ...prev, showNoBarberErrorModal: true }));
        } else {
          setBarbers(barbersData);
          setUiState((prev) => ({ ...prev, showNoBarberErrorModal: false }));
        }
      }
    } catch (error) {
      // Handle error
      setUiState({ loading: false, showErrorModal: true, showNoBarberErrorModal: false });
    } finally {
      isFetchingData.current = false;
      setUiState((prev) => ({ ...prev, loading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTickets = useCallback(async () => {
    if (isFetchingTickets.current === true) return;

    isFetchingTickets.current = true;

    if (checkInData.user.formattedNumber) {
      const body = {
        phoneNumber: checkInData.user.formattedNumber,
      };
      Fetch(`/webcheckin/CleanTicketsForPhoneNumber`, "POST", JSON.stringify(body))
        .then((data) => {
          dispatch(setTickets(data));
        })
        .catch((error) => {
          isFetchingTickets.current = false;
        })
        .finally(() => {
          isFetchingTickets.current = false;
        });
    } else {
      isFetchingTickets.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInData.user.formattedNumber]);

  const devModeVerification = () => {
    const code = CryptoJS.SHA256(process.env.REACT_APP_DEV_SECRET).toString();
    if (code !== checkInData.user.verificationCode) {
      return false;
    }
    return true;
  };

  function isCanvasSupported() {
    var elem = document.createElement("canvas");
    var result = !!(elem.getContext && elem.getContext("2d"));
    elem = null; // Release reference
    return result;
  }

  const isEmbedded = () => {
    if (window.self !== window.top) {
      return true; //Embedded
    } else {
      return false;
    }
  };

  // Initial Shop Information Request
  useEffect(() => {
    fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  // Timed data pulls for shopInfo and Tickets
  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      if (isFetchingData.current === false) {
        fetchData(false);
      }
    }, 30000); // Fetch data every 30 seconds

    const fetchTicketsInterval = setInterval(() => {
      if (isFetchingTickets.current === false) {
        fetchTickets();
      }
    }, 10000); // Fetch tickets every 10 seconds

    // Cleanup intervals
    return () => {
      clearInterval(fetchDataInterval);
      clearInterval(fetchTicketsInterval);
    };
  }, [fetchData, fetchTickets, isFetchingData]);

  // Redux State Reset Handling
  useEffect(() => {
    if (resetState) {
      // Set a timeout to dispatch the action after a delay
      const timerId = setTimeout(() => {
        dispatch(setResetState(false));
      }, 0);

      // Cleanup function to clear the timeout if the component is unmounted
      return () => clearTimeout(timerId);
    }
  }, [dispatch]);

  // User Inactivity monitoring
  useEffect(() => {
    // Add listeners for user interactions
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("keypress", resetTimer);
    document.addEventListener("touchstart", resetTimer);

    // Start the inactivity timer
    resetTimer();

    // Clear the inactivity timer on unmount
    return () => {
      clearTimeout(inactivityTimer.current);
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keypress", resetTimer);
      document.removeEventListener("touchstart", resetTimer);
    };
  }, [resetTimer]);

  // DevMode support
  useEffect(() => {
    if (process.env.REACT_APP_DEV_ENABLED === "true") {
      if (!devModeVerification()) {
        dispatch(setCurrentStep(Steps.DEV_GATEWAY));
      }
    } else if (checkInData.currentGuest === null) {
      dispatch(setCurrentGuest(Steps.LOGIN));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Screen width monitoring
  useEffect(() => {
    window.matchMedia("(max-width: 991px)").addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  // Shop Status monitoring
  useEffect(() => {
    if (checkInData.shopInfo?.shopStatus !== "Open" && process.env.REACT_APP_DEV_ENABLED !== "true") {
      dispatch(setCurrentStep(Steps.LOGIN));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInData.shopInfo?.shopStatus]);

  useEffect(() => {
    // Send to GA TGM
    // console.log("waittime",checkInData?.shopInfo?.waitTime, checkInData?.shopInfo?.waitTime || null);
    logPageView("page_view", {
      shop_name: checkInData?.shopInfo?.hours?.shopName || sanitizedId.toString(),
      shop_country: checkInData?.shopInfo?.country || null,
      page: getStepName(checkInData.currentStep).toString(),
      wait_time: checkInData?.shopInfo?.waitTime || null,
      shop_status: checkInData?.shopInfo?.shopStatus || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInData.currentStep]);

  if (uiState.loading) {
    return <AppModal modalState={uiState.loading} onModalClose={() => {}} shouldRedirect={false} buttons={{ close: false, retry: false, loading: true, ok: false }} />;
  }

  if (showInactivityModal && checkInData.currentStep !== Steps.LOGIN && checkInData.currentStep !== Steps.DEV_GATEWAY && checkInData.currentStep !== Steps.FINAL_CONFIRMATION) {
    return (
      <AppModal
        modalState={showInactivityModal}
        title="Are you still there..."
        message="You have been inactive for 2 minutes. Please click 'RESUME' to continue."
        // Include other props as needed
        onModalClose={() => setShowInactivityModal(false)}
        buttons={{ close: true, retry: false, loading: false, ok: false }}
        inactive={true}
      />
    );
  }

  if (uiState.showErrorModal || (checkInData.shopInfo?.shopStatus === "Open" && uiState.showNoBarberErrorModal)) {
    // Send to GA TGM
    logPageView("error_modal", {
      shop_name: checkInData?.shopInfo?.hours?.shopName || sanitizedId.toString(),
      shop_country: checkInData?.shopInfo?.country || null,
      page: getStepName(checkInData.currentStep).toString(),
      wait_time: checkInData?.shopInfo?.waitTime || null,
      shop_status: checkInData?.shopInfo?.shopStatus || null,
      error_message: uiState.showNoBarberErrorModal ? "Checkin unavailable - no barbers available" : "Checkin unavailable - unable to reach shop",
    });

    return (
      <AppModal
        modalState={uiState.showErrorModal || uiState.showNoBarberErrorModal}
        title="Sorry..."
        message={`${
          checkInData?.shopInfo?.hours?.shopName ? checkInData?.shopInfo?.hours?.shopName : "This Shops"
        } Online Check-In is unavailable right now, please wait while we bring it back Online.`}
        shopNumber={checkInData?.shopInfo?.businessNumber}
        country={checkInData?.shopInfo?.country}
        shouldRedirect={false}
        buttons={{ close: isEmbedded(), retry: false, loading: false }}
        retryFunction={fetchData}
        onModalClose={() => {}}
      />
    );
  }

  console.log("rendered web-check-in");
  return (
    <ErrorBoundary>
      <>
        {checkInData.currentStep === Steps.DEV_GATEWAY && <DevGateway isMobile={matches} />}
        {checkInData.currentStep === Steps.LOGIN && <Login device={{ udf: userDeviceFingerprint, udi: userDeviceInfo }} isSupported={isCanvasSupported} isMobile={matches} />}
        {checkInData.currentStep === Steps.VERIFICATION && <Verification device={{ udf: userDeviceFingerprint, udi: userDeviceInfo }} isSupported={isCanvasSupported} isMobile={matches} />}
        {checkInData.currentStep === Steps.REGISTER && <Register isMobile={matches} />}
        {checkInData.currentStep === Steps.GUEST_SELECT && <GuestSelect cancelServiceCallback={handleCancelCheckIn} isMobile={matches} />}
        {checkInData.currentStep === Steps.MANAGE_ACCOUNT && <ManageAccount device={{ udf: userDeviceFingerprint, udi: userDeviceInfo }} isMobile={matches} />}
        {checkInData.currentStep === Steps.MANAGE_GUESTS && <ManageGuests isMobile={matches} />}
        {checkInData.currentStep === Steps.ADD_GUEST && <AddGuest isMobile={matches} />}
        {checkInData.currentStep === Steps.MANAGE_TRUSTED_DEVICES && <TrustedDeviceManager isMobile={matches} />}
        {checkInData.currentStep === Steps.GROUP_SERVICES && <GroupServices isMobile={matches} />}
        {checkInData.currentStep === Steps.SELECT_SERVICES && <SelectServices currentUserIndex={checkInData.currentGuest} isMobile={matches} />}
        {checkInData.currentStep === Steps.SELECT_BARBER && <SelectBarber currentUserIndex={checkInData.currentGuest} isMobile={matches} barbersData={barbers} retryCallback={fetchData(false)} />}
        {checkInData.currentStep === Steps.SUMMARY && <Summary barbersData={barbers} isMobile={matches} />}
        {checkInData.currentStep === Steps.FINAL_CONFIRMATION && <FinalConfirmation cancelServiceCallback={handleCancelCheckIn} isMobile={matches} />}
      </>
    </ErrorBoundary>
  );
}

export default WebCheckIn;
